import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { PrismicPage } from '@types';
import styled from '@emotion/styled';
import { Slices } from '../components/prismicSlices';

type PageProps = {
  data: {
    prismicHomepage: PrismicPage;
  };
};

const Container = styled.div`
  height: calc(100vh - 250px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
`;

export default (props: PageProps) => (
  // const { title, body } = props.data.prismicHomepage.data;
  <Container>
    <h1>Þessi síða er í vinnslu!</h1>
  </Container>
);
// return (
//   <Fragment>
//     {body.map((slice) => {
//       const { slice_type, primary, id, ...rest } = slice;
//       const SliceComponent = Slices[slice_type];
//       if (!SliceComponent) {
//         return <Slices.missing>{slice_type}</Slices.missing>;
//       }
//       return <SliceComponent key={id} {...primary} {...rest} />;
//     })}
//   </Fragment>
// );

// export const query = graphql``;
// export const query = graphql`
//   query getHomePage {
//     prismicHomepage {
//       data {
//         title {
//           text
//         }
//         body {
//           ... on PrismicHomepageBodyBigHero {
//             slice_type
//             id
//             primary {
//               hero_image {
//                 localFile {
//                   childImageSharp {
//                     fluid {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           ... on PrismicHomepageBodyMissionStatement {
//             slice_type
//             id
//             primary {
//               mission_title {
//                 html
//                 text
//               }
//               mission_subtitle {
//                 html
//                 text
//               }
//               mission_description {
//                 html
//                 text
//               }
//             }
//           }

//           ... on PrismicHomepageBodyFeaturedPackages {
//             slice_type
//             id
//             primary {
//               featured_packages_title {
//                 html
//                 text
//               }
//               featured_packages_link {
//                 uid
//               }
//             }
//             items {
//               featured_package {
//                 document {
//                   uid
//                   data {
//                     title {
//                       text
//                     }
//                     card_rusty_top_text {
//                       text
//                     }
//                     card_rusty_center_text {
//                       text
//                     }
//                     card_rusty_bottom_text {
//                       text
//                     }
//                     card_image {
//                       localFile {
//                         childImageSharp {
//                           fluid {
//                             ...GatsbyImageSharpFluid
//                           }
//                         }
//                       }
//                     }
//                     price
//                     card_description {
//                       text
//                     }
//                   }
//                 }
//               }
//             }
//           }

//           ... on PrismicHomepageBodyFeaturedActivities {
//             slice_type
//             id
//             primary {
//               featured_activities_link {
//                 uid
//               }
//               featured_activities_title {
//                 html
//                 text
//               }
//             }
//             items {
//               featured_activity {
//                 document {
//                   uid
//                   data {
//                     price
//                     title {
//                       text
//                     }
//                     description {
//                       text
//                     }
//                     image {
//                       localFile {
//                         childImageSharp {
//                           fluid {
//                             ...GatsbyImageSharpFluid
//                           }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }

//           ... on PrismicHomepageBodyTripAdvisorBanner {
//             slice_type
//             id
//             primary {
//               trip_advisor_title {
//                 html
//                 text
//               }
//               trip_advisor_description {
//                 html
//                 text
//               }
//               trip_advisor_paragraph_above {
//                 html
//                 text
//               }
//             }
//           }
//           ... on PrismicHomepageBodyFeaturedBlogs {
//             slice_type
//             id
//             primary {
//               featured_blogs_title {
//                 html
//                 text
//               }
//               featured_blogs_link {
//                 uid
//               }
//             }
//             items {
//               featured_blog {
//                 document {
//                   uid
//                   last_publication_date
//                   data {
//                     card_icon
//                     title {
//                       text
//                     }
//                     blog_post_summary {
//                       text
//                     }
//                     blog_post_image {
//                       localFile {
//                         childImageSharp {
//                           fluid {
//                             ...GatsbyImageSharpFluid
//                           }
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           ... on PrismicHomepageBodyInstagramBanner {
//             slice_type
//             id
//             primary {
//               instagram_banner_title {
//                 text
//               }
//               instagram_banner_subtitle {
//                 text
//               }
//             }
//             items {
//               instagram_banner_image {
//                 localFile {
//                   childImageSharp {
//                     fluid {
//                       ...GatsbyImageSharpFluid
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
